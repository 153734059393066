exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-auth-api-jsx": () => import("./../../../src/templates/authApi.jsx" /* webpackChunkName: "component---src-templates-auth-api-jsx" */),
  "component---src-templates-examples-jsx": () => import("./../../../src/templates/examples.jsx" /* webpackChunkName: "component---src-templates-examples-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-pages-index-jsx": () => import("./../../../src/templates/pagesIndex.jsx" /* webpackChunkName: "component---src-templates-pages-index-jsx" */),
  "component---src-templates-pagination-jsx": () => import("./../../../src/templates/pagination.jsx" /* webpackChunkName: "component---src-templates-pagination-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-posts-index-jsx": () => import("./../../../src/templates/postsIndex.jsx" /* webpackChunkName: "component---src-templates-posts-index-jsx" */)
}

